.white {
    display: flex;
   color: white;
   margin-right: 0;
   align-self: center;
   font-weight: bold;
}

.body2 {
    display: flex;
    background-image: url('../assets/lightbulb.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 25%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;


}

.body2-banner{
    display: flex;
    color: white;
    margin-top: 0%;
    align-self: center;
     
}

.body2-container {
    display: flex;
    align-content: flex-start;
    margin-top: 9%;
    margin-left: 25%;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    
}

.body2-button {
    width: 300px;
    height: 170px;
    background-color: rgb(236, 149, 233);
    padding: 1rem;
    color: black;
    border: 2px solid grey;
    border-radius: 50px;
    cursor: pointer;
    font-family: 'Karla', sans-serif;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 300px;
    font-weight: bolder;
    font-size: 30px;
}

.body2-textContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    
}

.body2-description {
    display: flex;
    color: white;
    align-self: flex-start;
    margin-left: 10px;
    margin-bottom: 1px;
    
}

.body2-textBox {
    display: flex;
    color: lightgrey;
    align-self: center;
    margin-left: 15px;
    margin-bottom: 10px;
    max-width: 550px;
    font-size: larger;
   

}

.body2-textBox3 {
    display: flex;
    color: lightgrey;
    align-self: flex-start;
    margin-left: 130px;
    margin-bottom: 10px;
    max-width: 550px;
    font-size: larger;
    font-weight: bold;
}

.ul {
    display: flex;
    list-style-type: disc;
    color: whitesmoke;
    flex-direction: column;
    align-items: center;

}



button:hover {
    background-color: #FFFEF1;
    color: #2C5E2E;
}

button:focus {
    outline: 0;
}